<template>
    <div>
        <div class="columns is-mobile is-multiline">
            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Name') }}</label>
                    <div class="control">
                        <input class="input" type="text" v-model="rule.name" disabled>
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile">
                <div class="field" v-if="rule.variable">
                    <label class="label">{{ i18n('Variable') }}</label>
                    <div class="control">
                        <input class="input" type="text" v-model="rule.variable.name" disabled>
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Initial value') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               v-model="rule.initial_value" disabled>
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Final value') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               v-model="rule.final_value" disabled>
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile" v-if="rule.points">
                <div class="field">
                    <label class="label">{{ i18n('Points') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               v-model="rule.points" disabled>
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile" v-if="rule.value">
                <div class="field">
                    <label class="label">{{ i18n('Value') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               v-model="rule.value" disabled>
                    </div>
                </div>
            </div>
            </div>
    </div>
</template>

<script>
export default {
    name: 'Show',
    inject: ['route', 'http', 'i18n'],
    data() {
        return {
            rule: {},
        };
    },

    mounted() {
        this.fetchData();
    },

    methods: {
        fetchData() {
            this.http
                .get(this.route('rule.show', { rule: this.$route.params.rule }))
                .then(({ data }) => {
                    this.rule = data.rule;
                })
                .catch(this.errorHandler);
        },
    },
};
</script>

<style lang="scss" scoped>
.medal {
    width: 10rem;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

@media (max-width: 748px) {
    .medal {
        width: 5.5rem;
    }
}
</style>
